<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'600px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        :rules="rules"
        ref="form"
        :label-width="'150px'"
        label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }"
        size="large"
      >
        <el-row :gutter="20" v-if="deviceInfoForm.parentId">
          <el-col :span="12">
            <el-form-item prop="parentId" :label="$t('add.上级组织') + '：'">
              {{ deviceInfoForm.parentName }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="orgName" :label="$t('add.组织机构') + '：'">
              <el-input
                autocomplete="off"
                v-model="deviceInfoForm.orgName"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="remark" :label="$t('add.备注') + '：'">
              <el-input
                autocomplete="off"
                v-model="deviceInfoForm.remark"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="close()"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{
            $t("global.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import md5 from 'js-md5'
import base from '@/templates/add'
import { treearr } from '@/tool'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: '',
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      deviceInfoForm: {
        loginName: '',
        userName: '',
        password: '',
        phone: '',
        roleId: [],
        orgId: [],
        status: ''
      },
      rules: {
        orgName: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.input') }
        ],
        remark: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ]
      }
    }
  },
  created () {
  },
  methods: {
    show () {
      this.dialogFormVisible2 = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add (data) {
      this.titleTable = this.$t('add.新增组织')
      this.isModelAdd = true
      this.deviceInfoForm = { ...(data || {}) }
      console.log(data)
      this.show()
    },
    edit (data) {
      this.titleTable = this.$t('add.编辑组织')
      this.isModelEdit = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    view (data) {
      this.titleTable = this.$t('add.组织详情')
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm () {
      this.deviceInfoForm = {}
      this.$refs.form.resetFields()
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.posting = true
          const query = {
            ... this.deviceInfoForm,
            id: this.deviceInfoForm.id,
          }
          try {
            await api.auth[this.isModelAdd ? 'postAuthAddOrg' : 'postAuthUpdateOrg'](query)
            this.posting = false
            this.$message.success(this.$t('global.submitOk'))
            this.close()
            this.$parent.onSubmit()
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
