<template>
  <div
    class="g-box g-role-list-box g-role-add-box-padding"
    style="padding-left: 0; padding-right: 0"
  >
    <div class="left" v-loading="treeLoading">
      <div class="treebox">
        <div
          style="
            position: relative;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
          "
        >
          <el-tree
            ref="tree"
            :data="treeData"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="treeNodeClick"
          >
            <div slot-scope="{ node, data }" class="my-tree-node">
              <span>
                {{ data[defaultProps.label] }}
              </span>
              <div
                v-if="
                  !data[defaultProps.children] ||
                  (data[defaultProps.children].length < 1 && treeAction.show)
                "
              >
                <el-button
                  circle
                  icon="iconfont el-icon-circle-plus-outline primary-color"
                  size="mini"
                  v-if="treeAction.add && treeAction.add.show"
                  @click.stop="treeAdd(data)"
                  :title="treeAction.add.title"
                ></el-button>
                <el-button
                  circle
                  :icon="'iconfont icon-bianji primary-color'"
                  size="mini"
                  v-if="treeAction.edit && treeAction.edit.show"
                  @click.stop="treeEdit(data)"
                  :title="treeAction.edit.title"
                ></el-button>
                <el-button
                  circle
                  icon="iconfont icon-shanchu danger-color"
                  size="mini"
                  v-if="treeAction.remove && treeAction.remove.show"
                  @click.stop="treeRemove(data)"
                  :title="treeAction.remove.title"
                ></el-button>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
    </div>
    <div class="right" v-loading="loading">
      <div
        style="
          position: relative;
          width: 100%;
          height: 100%;
          overflow-x: hidden;
        "
      >
        <div class="list-box">
          <slot-top
            v-if="$options.components.slotTop"
            :slot-top-data="slotTopData"
            ref="slotTop"
          >
          </slot-top>
          <div class="m-form-inline" data-m="select">
            <div class="m-form-inline-mn">
              <table-filter
                :show-list="showList"
                :hide-list="hideList"
                v-model="formInline"
                @filterChange="toPage(1)"
              ></table-filter>
            </div>
          </div>
          <div class="m-list" data-m="list">
            <m-table-icons
              @sizeChange="(val) => (tableSize = val)"
              :columns="tableTileTemp"
              @columnChange="ddd"
            >
              <div slot="btns" style="width: 100%">
                <div class="my-btns">
                  <el-button
                    style="width: 80px"
                    type="primary"
                    @click="add"
                    size="small"
                    v-if="tableAction.add && tableAction.add.show"
                  >
                    {{ $t("global.add") }}
                  </el-button>
                </div>
              </div>
            </m-table-icons>
            <el-table
              :data="tableData"
              :size="tableSize"
              tooltip-effect="dark"
              ref="multipleTable"
            >
              <el-table-column
                align="center"
                v-for="item in tableTile"
                :label="$t(item.columnLabel)"
                :prop="item.prop"
                :type="item.type ? item.type : ''"
                :key="item.key"
                :width="item.width"
                :selectable="item.selectable ? item.selectable : null"
                :formatter="item.formatter ? item.formatter : null"
                :fixed="item.fixed ? item.fixed : null"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                fixed="right"
                :label="$t('global.操作')"
                :width="handleTableColumnWidth"
                v-if="tableAction.show"
              >
                <template slot-scope="scope">
                  <el-button
                    circle
                    icon="iconfont icon-xiangqing primary-color"
                    :title="
                      tableAction.view
                        ? tableAction.view.title
                        : $t('global.details')
                    "
                    size="mini"
                    @click="view(scope.row.id)"
                    v-if="!tableAction.view || tableAction.view.show"
                  ></el-button>
                  <!--            tool.checkButton('role-info')-->
                  <el-button
                    circle
                    :icon="
                      tableAction.edit && tableAction.edit.icon
                        ? tableAction.edit.icon
                        : 'iconfont icon-bianji primary-color'
                    "
                    :title="
                      tableAction.edit
                        ? tableAction.edit.title
                        : $t('global.edit')
                    "
                    size="mini"
                    @click="edit(scope.row.id)"
                    v-if="
                      !tableAction.edit ||
                      (tableAction.edit.show && !tableAction.edit.condition) ||
                      (tableAction.edit.show && !tableAction.edit.condition) ||
                      (tableAction.edit.show &&
                        checkCondition(scope.row, tableAction.edit.condition))
                    "
                  ></el-button>
                  <el-button
                    circle
                    icon="iconfont icon-shanchu danger-color"
                    :title="$t('global.remove')"
                    size="mini"
                    @click="remove(scope.row.id)"
                    v-if="
                      !tableAction.remove ||
                      (tableAction.remove.show &&
                        !tableAction.remove.condition) ||
                      (tableAction.remove.show &&
                        !tableAction.remove.condition) ||
                      (tableAction.remove.show &&
                        checkCondition(scope.row, tableAction.remove.condition))
                    "
                  ></el-button>
                  <template v-if="!tableAction.valid || tableAction.valid.show">
                    <el-button
                      circle
                      icon="iconfont icon-qiyong warn-color"
                      :title="$t('global.enable')"
                      v-if="scope.row.status === 1"
                      size="mini"
                      @click="disable(scope.row.id)"
                    ></el-button>
                    <el-button
                      circle
                      icon="iconfont icon-tingyongzice warn-color"
                      :title="$t('global.valid')"
                      v-else-if="scope.row.status === 0"
                      size="mini"
                      @click="enable(scope.row.id)"
                    ></el-button>
                  </template>
                  <!--            <template-->
                  <!--              v-if="tableAction.updateStatus && tableAction.updateStatus.show"-->
                  <!--            >-->
                  <!--              <el-button-->
                  <!--                circle-->
                  <!--                icon="iconfont icon-tingyongzice warn-color"-->
                  <!--                :title="$t(tableAction.title)"-->
                  <!--                size="mini"-->
                  <!--                @click="updateStatus(scope.row)"-->
                  <!--              ></el-button>-->
                  <!--            </template>-->
                  <!--            <el-button-->
                  <!--              circle-->
                  <!--              @click="funFinish(scope.row.id, 1)"-->
                  <!--              icon="iconfont el-icon-check"-->
                  <!--              title="完成"-->
                  <!--              size="mini"-->
                  <!--              v-if="-->
                  <!--                isOKBtn &&-->
                  <!--                scope.row.isFinished !== 1 &&-->
                  <!--                $store.state.menuCode.includes('task-list-OK-Btn')-->
                  <!--              "-->
                  <!--            ></el-button>-->
                  <!--            <el-button-->
                  <!--              circle-->
                  <!--              @click="funFinish(scope.row.id, 0)"-->
                  <!--              icon="iconfont el-icon-refresh"-->
                  <!--              title="撤回"-->
                  <!--              size="mini"-->
                  <!--              v-if="-->
                  <!--                isOKBtn &&-->
                  <!--                scope.row.isFinished === 1 &&-->
                  <!--                $store.state.menuCode.includes('task-list-OK-Btn')-->
                  <!--              "-->
                  <!--            ></el-button>-->
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            class="m-page"
            :class="tableData.length <= 10 ? 'm-page2' : ''"
            data-m="tree-list"
            v-if="formInline.page.total > 0"
          >
            <p class="u-p">
              <!--        总共{{ formInline.page.total }}条数据-->
            </p>
            <el-pagination
              background
              layout="prev, pager, next, sizes, jumper"
              :total="formInline.page.total"
              :page-size.sync="formInline.page.size"
              :current-page="formInline.page.currentPage"
              @current-change="toPage"
              :page-sizes="[10, 20, 30, 40]"
              @size-change="toPage(1)"
            ></el-pagination>
          </div>

          <my-dialog v-if="$options.components.MyDialog" ref="myDialog">
          </my-dialog>

          <my-dialog2 v-if="$options.components.MyDialog2" ref="myDialog2">
          </my-dialog2>

          <my-dialog3 v-if="$options.components.MyDialog3" ref="myDialog3">
          </my-dialog3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import tableFilter from '@/components/table-filter'
import mTableIcons from '@/components/m-table-icons'
import tool, { treearr } from '@/tool'
import MyDialog2 from './../user/dialog-info.vue'
import MyDialog from './dialog'
export default {
  components: { tableFilter, mTableIcons, MyDialog2, MyDialog },
  data () {
    return {
      isOKBtn: false,
      handleTableColumnWidth: 180,
      tableSize: 'medium',
      tableTileTemp: [],
      tableAction: {
        // 操作栏显示的按钮
        show: true,
        add: { show: true },
        remove: { show: true },
        edit: { show: false },
        view: { show: false },
        valid: { show: false }
      },
      showExport: true,
      showList: [
        {
          copName: 'el-input',
          placeholder: 'list.登录名/用户名',
          key: 'query',
          name: '',
          attrs: ['placeholder'],
          width: 180
        },
        {
          copName: 'el-select',
          key: 'status',
          type: '',
          attrs: ['placeholder', 'options'],
          placeholder: 'list.状态',
          options: [
            { label: this.$t('global.启用'), value: '0' },
            { label: this.$t('global.停用'), value: '1' }
          ],
          width: 180
        },
        {
          copName: 'el-select',
          key: 'roleId',
          type: '',
          attrs: ['placeholder', 'options'],
          placeholder: 'list.角色',
          options: [
          ],
          width: 180
        }
        // {
        //   copName: 'el-cascader',
        //   placeholder: '所属组织',
        //   key: 'orgId',
        //   attrs: ['placeholder', 'cascader', 'options', 'props', 'filterable'],
        //   filterable: true,
        //   props: { checkStrictly: true, value: 'id', label: 'orgName' },
        //   cascaderOptions: [
        //   ]
        // }
      ],
      hideList: [],
      formInline: {
        name: '',
        page: {
          total: 0,
          size: 10,
          currentPage: 1
        }
      },
      tableData: [],
      tableTile: [{
        key: '1',
        columnLabel: 'list.登录名',
        prop: 'loginName',
        width: ''
      }, {
        key: '2',
        columnLabel: 'list.用户名',
        prop: 'userName',
        width: ''
      }, {
        key: 'status',
        columnLabel: 'list.状态',
        prop: 'status',
        width: '',
        formatter: (row, column, cellValue, index) => {
          let temp = ''
          switch (cellValue) {
            case 0:
              temp = this.$t('global.启用')
              break
            case 1:
              temp = this.$t('global.停用')
              break
          }
          return temp
        }
      }, {
        key: '4',
        columnLabel: 'list.角色',
        prop: 'roleName',
        width: ''
      }, {
        key: '5',
        columnLabel: 'list.组织机构',
        prop: 'orgName',
        width: ''
      }],
      loading: true,
      json_fileName: '导出数据.xls',
      json_fields: {},
      json_data: [],
      exportLoading: false,
      exportLoadingPartIndex: 1,
      exportLoadingPartTotal: 10,
      exportFetching: false,
      slotTopData: {},
      treeLoading: false,
      treeData: [],
      defaultProps: {
        children: 'childrenList',
        label: 'orgName'
      },
      treeAction: {
        // tree操作
        show: true,
        add: { show: true, title: '新增' },
        edit: { show: true, title: '编辑' },
        remove: { show: true, title: '删除' }
      },
      currentNode: {}
    }
  },
  created () {
    this.init()
    api.auth.getAuthRoleAllRole().then(res => {
      this.showList[2].options = res.data.map(item => ({ ...item, label: item.roleName, value: item.id }))
    })
  },
  methods: {
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    add () {
      if (this.currentNode.id) {
        this.$refs.myDialog.add({ parentId: this.currentNode.id, parentName: this.currentNode[this.defaultProps.label] })
      } else {
        this.$refs.myDialog.add({})
      }
    },
    onSubmit () {
      this.loading = true
      api.organization.queryOrgTree({}).then(res => {
        this.treeData = res.data
        this.loading = false
      })
      api.auth.getAuthUserList({
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          ...this.formInline
        }
      }).then(res => {
        this.tableData = res.data.records
        this.formInline.page.total = res.data.total
        this.loading = false
      })
    },
    // 详情
    async view (id) {
      const res = await api.auth.getAuthFindUser({ id })
      this.$refs.myDialog2.view({
        ...(res.data || {})
      })
    },
    // 删除
    async remove (id) {
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      await api.auth.getAuthDeleteUser({
        query: {
          id: id
        }
      })

      this.toPage(1)
    },
    ddd () { },
    checkCondition (item, condition) {
      var ok = true
      condition.forEach((ele) => {
        if (ele.value instanceof Array) {
          ok = false

          ele.value.forEach((ele2) => {
            if (item[ele.key] === ele2) {
              ok = true
            }
          })
        } else if (item[ele.key] !== ele.value) {
          ok = false
        }
      })
      return ok
    },
    treeNodeClick (node, data) {
      this.currentNode = node
      this.formInline.orgId = node.id
      this.toPage(1)
    },
    treeAdd (node) {
      this.$refs.myDialog.add({ parentId: node.id, parentName: node[this.defaultProps.label] })
    },
    treeEdit (node) {
      api.auth.getAuthFindOrg({ query: { id: node.id } }).then((res) => {
        this.$refs.myDialog.edit({ ...res.data, parentId: res.data.parentId, parentName: res.data.parentOrgName })
      })
    },
    async treeRemove (node) {
      console.log(node)
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      await api.auth.postAuthDeleteOrg({
        query: {
          id: node.id
        }
      })

      this.toPage(1)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/global-variable";
.g-role-list-box {
  display: flex;
  height: calc(100vh - 125px) !important;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 12px 20px;
  .left {
    width: 20%;
    box-sizing: border-box;
    background: #fafafa;
    padding: 0px 20px;
  }
  .right {
    background-color: #fff;
    width: 80%;
    box-sizing: border-box;
  }
  .treebox {
    box-sizing: border-box;
    background-color: #fff;
    border: solid 1px #dcdfe6;
    border-radius: 10px;
    height: 100%;

    /deep/.el-tree {
      padding: 12px;
      height: 100%;
      .el-tree-node {
        .el-tree-node__children {
          overflow-x: auto;
          overflow-y: none;
        }
        .my-tree-node {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .el-button--mini.is-circle {
          padding: 5px;
        }
        .el-button + .el-button {
          margin: 0;
        }
      }
    }
  }
  .list-box {
    padding: 0px 20px;
  }
}
</style>
