<template>
  <div class="u-form-status">
    <span
      class="u-form-status-it" v-for="(item,index) in copOptions" :key="index"
      :data-active="value===item.value" @click="funChange(item.value)"
    >{{ item.label }}</span>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    copOptions: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    funChange (code) {
      this.value = code
      this.$emit('change', code)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./../assets/css/global-variable";
.u-form-status{
    display:flex;
    justify-content: space-between;
    // align-items: flex-end;
    height:36px;
    // padding-left:20px;
    .u-form-status-it{
      height:30px;
      cursor: pointer;
      white-space: nowrap;
      border-bottom:2px solid transparent;
      &[data-active]{
        color:$fc-theme;
        border-bottom:2px solid $fc-theme;
      }
    }
}
</style>
